// TPDirect sdk version
const TAPPAY_SDK_VERSION = '5.15.0';
// tappay sdk url
const TAPPAY_SDK_URL = `https://js.tappaysdk.com/sdk/tpdirect/v${TAPPAY_SDK_VERSION}`;
// tappay app id
const TAPPAY_APP_ID = process.env.GATSBY_TAPPAY_APP_ID;
// tappay app key
const TAPPAY_APP_KEY = process.env.GATSBY_TAPPAY_APP_KEY;
// tappay server type
const TAPPAY_SERVER_TYPE = process.env.GATSBY_TAPPAY_SERVER_TYPE;
// apple merchantIdentifier
const APPLE_MERCHANT_IDENTIFIER = process.env.GATSBY_APPLE_MERCHANT_IDENTIFIER;

// initailize tap pay
const initTPDirect = () => {
  return new Promise((resolve, reject) => {
    if (window.TPDirect) {
      console.log('TPDirect already exists');
      resolve(window.TPDirect);
    } else {
      console.log('TPDirect not exists');
      const script = document.createElement('script');
      script.src = TAPPAY_SDK_URL;
      script.onload = () => {
        resolve(window.TPDirect);
      };
      document.body.appendChild(script);
    }
  });
};

// applepay data format method
const getApplePayDataFmt = (amountValue) => {
  const applePayDataFmt = {
    supportedNetworks: ['MASTERCARD', 'VISA', 'AMEX'],
    supportedMethods: ['apple_pay'],
    displayItems: [],
    total: {
      label: '家速配',
      amount: {
        currency: 'TWD',
        value: amountValue,
      },
    },
    shippingOptions: [],
    // optional
    options: {
      requestPayerEmail: false,
      requestPayerName: false,
      requestPayerPhone: false,
      requestShipping: false,
    },
  };
  return applePayDataFmt;
};

const applePayAvailable = async () => {
  const TPDirect = await initTPDirect();
  if (!TPDirect.paymentRequestApi.checkAvailability()) {
    console.log('不支援apple pay');
    return false;
  }

  console.log('支援apple pay');
  return true;
};

/*
實作範例：
  checkApplePay(124)
  .then(() => {
    alert('apple pay available');
  })
  .catch((msg) => alert(msg));
*/
// 實作TPDirect使用apple pay
const checkApplePay = async (amountValue) => {
  const TPDirect = await initTPDirect();
  return new Promise((resolve, reject) => {
    if (!TPDirect.paymentRequestApi.checkAvailability()) {
      reject('裝置不支援apple pay'); // 不支援apple pay
      return;
    }
    TPDirect.setupSDK(TAPPAY_APP_ID, TAPPAY_APP_KEY, TAPPAY_SERVER_TYPE);

    TPDirect.paymentRequestApi.setupApplePay({
      // required, your apple merchant id
      merchantIdentifier: APPLE_MERCHANT_IDENTIFIER,
      // defaults to 'TW'
      countryCode: 'TW',
    });

    let data = getApplePayDataFmt(amountValue);

    TPDirect.paymentRequestApi.setupPaymentRequest(data, function (result) {
      console.log('apple pay payment request setup result', result);
      // 代表瀏覽器支援 payment request api (或 apple pay)
      // 和 TPDirect.paymentRequestApi.checkAvailability() 的結果是一樣的
      if (!result.browserSupportPaymentRequest) {
        console.log('裝置不支援apple pay');
        return reject('裝置不支援apple pay');
      }

      // 代表使用者是否有符合 supportedNetworks 與 supportedMethods 的卡片
      // paymentRequestApi ---> canMakePaymentWithActiveCard is result of canMakePayment
      // apple pay         ---> canMakePaymentWithActiveCard is result of canMakePaymentsWithActiveCard

      // NOTE: apple pay 只會檢查使用者是否有在 apple pay 裡面綁卡片
      if (result.canMakePaymentWithActiveCard) {
        console.log('裝置可以使用 PaymentRequest / Apple Pay');
      } else {
        // 如果有支援 basic-card 方式，仍然可以開啟 payment request sheet
        // 如果是 apple pay，會引導使用者去 apple pay 綁卡片
        console.log(
          '裝置支援 PaymentRequest / Apple Pay，但是沒有可以支付的卡片'
        );
        return reject('裝置支援Apple Pay，但是沒有可以支付的卡片');
      }

      if (window.ApplePaySession) {
        return resolve(true);
      }

      return reject('Apple Pay Session not exists');
    });
  });
};

/*
實作範例：
  applePay()
  .then((result)) => {})
  .catch((msg) => alert(msg));
*/
const applePay = async () => {
  const TPDirect = await initTPDirect();
  return new Promise((resolve, reject) => {
    TPDirect.paymentRequestApi.getPrime(function (result) {
      console.log('apple pay get prime result', result);
      if (result.status == 0) {
        return resolve({
          prime: result.prime,
          result: result,
        });
      }

      return reject(result.msg);
    });
  });
};

/*
  linePay()
    .then((result) => {
      console.log('line pay result', result);
    })
    .catch((msg) => {
      alert(msg);
    });

    // 回傳給後端後，後端會回傳一個 payment_url
    // 使用tapPayRedirect,(payment_url)即可跳轉到line pay頁面
    tapPayRedirect,(payment_url)
 */
// 實作TPDirect使用line pay
const linePay = async () => {
  const TPDirect = await initTPDirect();
  return new Promise((resolve, reject) => {
    TPDirect.setupSDK(TAPPAY_APP_ID, TAPPAY_APP_KEY, TAPPAY_SERVER_TYPE);
    TPDirect.linePay.getPrime((result) => {
      console.log('line pay get prime result', result);

      if (result.status === 0) {
        return resolve({
          prime: result.prime,
          result: result,
        });
      }
      return reject(result.msg);
    });
  });
};

/*
  jkoPay()
    .then((result) => {
      console.log('jko pay result', result);
    })
    .catch((msg) => {
      alert(msg);
    });

    // 回傳給後端後，後端會回傳一個 payment_url
    // 使用tapPayRedirect,(payment_url)即可跳轉到jko pay頁面
    tapPayRedirect,(payment_url)
 */
// 實作TPDirect使用JKO pay
const jkoPay = async (params) => {
  const TPDirect = await initTPDirect();
  return new Promise((resolve, reject) => {
    TPDirect.setupSDK(TAPPAY_APP_ID, TAPPAY_APP_KEY, TAPPAY_SERVER_TYPE);
    TPDirect.jkoPay.getPrime((result) => {
      console.log('jko pay get prime result', result);

      if (result.status === 0) {
        return resolve({
          prime: result.prime,
          result: result,
        });
      }
      return reject(result.msg);
    });
  });
};

// 實作TPDirect使用tapPayRedirect
const tapPayRedirect = async (payment_url) => {
  const TPDirect = await initTPDirect();
  TPDirect.redirect(payment_url);
};

export {
  checkApplePay,
  applePay,
  linePay,
  jkoPay,
  applePayAvailable,
  tapPayRedirect,
};
