import React, { useEffect } from 'react';
import { navigate } from 'gatsby';
import { PATH_HOME } from 'utils/constants/paths.js';
import { tapPayRedirect } from 'utils/tappay';
import TitleName from 'components/UI/Header/TitleName';

const ResultPage = ({ location }) => {
  useEffect(() => {
    if (location.state !== null) {
      const url = location.state.url;
      navigate(url, { replace: true });
    } else {
      navigate(PATH_HOME, { replace: true });
    }
  }, []);

  return (
    <>
      <TitleName />
    </>
  );
};

export default ResultPage;
